.common-filter-btn,
.common-filter-btn:hover {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: none;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px;
    color: #344054;
    gap: 3px;
    height: 40px;
    width: 100px;
}

.dashboard-filter-btn,
.dashboard-filter-btn:hover {
    display: flex;
    align-items: center;
    background-color: #FFFFFF;
    border: none;
    box-shadow: rgba(9, 30, 66, 0.25) 0px 0px 1px, rgba(9, 30, 66, 0.13) 0px 0px 0px 1px;
    color: #344054;
    gap: 3px;
    height: 40px;
    width: 100px;
}

.previousbtn,
.previousbtn:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 10px;
    color: #475467;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    width: 100px;
}

.nextbtn,
.nextbtn:hover {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-radius: 10px;
    color: #475467;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;
    width: 70px;
}

.save-btn-lite,
.save-btn-lite:hover{
font-family: "Inter";
width: 65px;
height: 40px;
font-size: 14px;
font-weight: 600;
line-height: 20px;
text-align: left;
color: #31A566;
background-color: #EDFFEE;
border: 1px solid #EDFFEE;
margin-top: 30px;
}

.form-add-btn,
.form-add-btn:hover{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    text-align: left;
    color: #344054;
    width: 130px;
    height: 40px;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
}

.form-save-btn,
.form-save-btn:hover{
    color: white;
    width: 130px;
    height: 40px;
}

.modal-cancel-btn,
.modal-cancel-btn:hover{
    font-family: "Inter";
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    text-align: left;
    color: #344054;
    height: 40px;
    border: 1px solid #D0D5DD;
    background-color: #FFFFFF;
}

.table-active-btn{
    background-color: #31A566;
    border: none;
    padding: 5px 10px;
    border-radius: 6px;
    color: #344054;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: white;
    cursor: pointer;
}

.table-inactive-btn{
    background-color: #f45858;
    border: none;
    padding: 5px 10px;
    border-radius: 6px;
    color: #344054;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    line-height: 18px;
    color: white;
    cursor: pointer;
}

.modal-delete-btn,
.modal-delete-btn:hover{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    text-align: left;
    color: white;
    height: 40px;
    border: 1px solid red;
    background-color: red;
}

.remove-btn,
.remove-btn:hover{
    margin-top: 26px;
    height: 43px !important;
    width: 100px;
    padding-left: 0px;
    padding-right: 0px;
}

.modal-reset-btn,
.modal-reset-btn:hover{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    border-radius: 8px;
    text-align: left;
    color: white;
    height: 40px;
    border: 1px solid red;
    background-color: red;
}